import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const SvenskdamStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          $slider: {
            $headline: {
              className: 'uppercase',
            },
          },
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default SvenskdamStandaloneRelatedArticleSlider;
